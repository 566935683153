<template>
  <div>
    <div class="row text-center">
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-1">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-user"></i><span>المديرين</span>
          </div>
          <div class="overview-box-count">{{ adminCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-2">
          <div class="overview-box-title">
            <i class="pi pi-map-marker"></i><span>المسابقات</span>
          </div>
          <div class="overview-box-count">{{ leagueCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-3">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-sitemap"></i><span>الاكاديميات</span>
          </div>
          <div class="overview-box-count">{{ academiesCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-4">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-id-card"></i><span>اللاعبين</span>
          </div>
          <div class="overview-box-count">{{ playersCount }}</div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        dataKey="id"
        :value="list"
        ref="dt"
        class="p-datatable-gridlines
        
        p-datatable-customers
      p-datatable-responsive-demo"
        :scrollable="true"
        style="max-width: 7055px"
      >
        <template #header>
          <div class="table-header">
            احصائيات الاكاديميات
            <!-- style="max-width: 12em" -->

            <vue-excel-xlsx
              :data="listXlsx"
              :columns="columnsx"
              filename="players"
              sheetname="players"
              style="background: transparent;border: none;"
            >
              <Button
                icon="pi pi-download"
                class="p-button-help"
                v-tooltip="'تحميل'"
              />
            </vue-excel-xlsx>

            <!-- <div style="text-align:left">
              <Dropdown
                v-model="selected"
                :options="leagueList"
                optionLabel="name"
                optionValue="id"
                style="max-width: 12em"
                @input="onToggle"
                placeholder="اختار مسابقه"
              />
            </div> -->
          </div>
        </template>
        <template #empty>
          لا يوجد بيانات
        </template>
        <template #loading>
          يتم تحميل البيانات. يرجي الانتظار...
        </template>
        <ColumnGroup type="header">
          <Row>
            <Column header="م" :rowspan="2" />
            <Column header="الاكاديميه" :rowspan="2" />
            <Column
              v-for="item of headerList"
              :key="item.id"
              :header="item.name"
              :colspan="item.teams.length"
            />

            <Column
              header="اجمالى عداد اللاعبين"
              v-if="subheaderList.length > 0"
              :rowspan="2"
            />
            <Column
              header="عداد الجهاز الفنى والادارى"
              v-if="subheaderList.length > 0"
              :rowspan="2"
            />
            <Column
              header="اجمالي الاكاديميه"
              v-if="subheaderList.length > 0"
              :rowspan="2"
            />
          </Row>

          <Row>
            <Column
              v-for="item of subheaderList"
              :key="item.id"
              :header="item.name"
              :field="'team' + item.id"
            />
          </Row>
        </ColumnGroup>
        <Column field="id" header="م">
          <template #body="slotProps">
            {{ slotProps.index + 1 }}
          </template>
        </Column>
        <Column field="userName" header="الاكاديميه" />

        <Column
          v-for="item of subheaderList"
          :key="item.id"
          :header="item.name"
        >
          <template #body="slotProps">
            {{ getCountTeams(slotProps.data, item) }}
          </template>
        </Column>
        <Column v-if="subheaderList.length > 0" header="اجمالى عداد اللاعبين">
          <template #body="slotProps">
            {{ getPlayerCount(slotProps.data) }}
          </template>
        </Column>
        <Column
          field="allCount"
          v-if="subheaderList.length > 0"
          header="عداد الجهاز الفنى والادارى"
        />
        <Column v-if="subheaderList.length > 0" header="اجمالي الاكاديميه">
          <template #body="slotProps">
            {{ getPlayerCount(slotProps.data) + slotProps.data.allCount }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      leagueList: [],
      adminCount: 0,
      leagueCount: 0,
      academiesCount: 0,
      playersCount: 0,
      selected: null,
      headerList: [],
      subheaderList: [],
      columnsx: [
        {
          field: 'id',
          label: 'الكود',
        },
        {
          field: 'userName',
          label: 'الاكاديميه',
        },
      ],
      listXlsx: [],
    };
  },

  methods: {
    getCountTeams(data, item) {
      if (data && data[item.yearName] && data[item.yearName][item.name]) {
        return data[item.yearName][item.name];
      }
      return 0;
    },

    getPlayerCount(item) {
      let c = 0;
      for (const key in item) {
        if (
          (key != 'id', key != 'userName', key != 'name', key != 'allCount')
        ) {
          for (const k in item[key]) {
            if (typeof item[key][k] == 'number') {
              c = c + item[key][k];
            }
          }
        }
      }
      return c;
    },
    getData() {
      // this.$http
      //   .get(`years/findAllYearsAcademie?leaguesId=${this.selected}`)
      //   .then((res) => {
      //     this.headerList = res.data;
      //     this.subheaderList = [];
      //     if (this.headerList.length > 0) {
      //       for (const item of this.headerList) {
      //         for (const itm of item.yearsTeams) {
      //           this.subheaderList.push(itm);
      //         }
      //       }
      //       this.$http
      //         .get(`players/byLeaguesId/${this.selected}`)
      //         .then((res2) => {
      //           this.list = res2.data;
      //         });
      //     }
      //   });
      // this.$http.get(`league/home`).then((res) => {
      //   this.headerList = res.data;
      //   this.subheaderList = [];
      //   if (this.headerList.length > 0) {
      //     for (const item of this.headerList) {
      //       for (const itm of item.teams) {
      //         this.subheaderList.push({
      //           yearName: item.name,
      //           ...itm,
      //         });
      //       }
      //     }
      //     this.$http
      //       .get(`players/byLeaguesId/${this.selected}`)
      //       .then((res2) => {
      //         this.list = res2.data;
      //       });
      //   }
      // });
    },
    onToggle(value) {
      this.getData();
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.$http.post(`users/count`).then((res) => {
      this.adminCount = res.data;
    });
    this.$http.post(`league/count`).then((res) => {
      this.leagueCount = res.data;
    });
    this.$http.post(`academies/count`).then((res) => {
      this.academiesCount = res.data;
    });
    this.$http.post(`players/count`).then((res) => {
      this.playersCount = res.data;
    });
    this.$http.get(`league/home`).then((res) => {
      this.headerList = res.data.year;

      this.subheaderList = [];
      if (this.headerList.length > 0) {
        for (const item of this.headerList) {
          for (const itm of item.teams) {
            this.subheaderList.push({
              yearName: item.name,
              ...itm,
            });
            this.columnsx.push({
              field: item.name + ' | ' + itm.name,
              label: item.name + ' | ' + itm.name,
            });
          }
        }
      }

      this.columnsx.push({
        field: 'totalplayer',
        label: 'اجمالى عداد اللاعبين',
      });

      this.columnsx.push({
        field: 'allCount',
        label: 'عداد الجهاز الفنى والادارى',
      });
      this.columnsx.push({
        field: 'total',
        label: 'اجمالي الاكاديميه',
      });

      this.list = [];

      this.$http
        .post(`players/byLeaguesId`, {
          id: res.data.id,
          list: res.data.list.map((el) => el.id),
        })
        .then((res2) => {
          const l = res2.data;
          for (let item of res.data.list) {
            const lf = l.find((el) => el.id == item.id);
            if (lf) {
              this.list.push({ ...item, allCount: lf.allCount });
            } else {
              this.list.push({ ...item, allCount: 0 });
            }
          }

          this.listXlsx = [];

          for (const item of this.list) {
            let obj = {
              id: item.id,
              userName: item.userName,
              allCount: item.allCount,
            };
            for (const key in item) {
              if (typeof item[key] == 'object') {
                for (const ky in item[key]) {
                  obj[key + ' | ' + ky] = item[key][ky];
                }
              }
            }
            this.listXlsx.push({
              ...obj,
              totalplayer: this.getPlayerCount(item),
              total: this.getPlayerCount(item) + item.allCount,
            });
          }
          // this.list = res.data.list;
        });
      // this.leagueList = res.data.year;
      // this.selected =
      //   this.leagueList.length > 0 ? this.leagueList[0].id : null;
      // this.getData();
    });
  },
};
</script>

<style>
.overview-box {
  padding: 15px;
  color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;
}
.overview-box .overview-box-title {
  font-weight: bold;
  width: 100%;
}
.overview-box .overview-box-title i {
  vertical-align: middle;
  font-size: 20px;
}
.overview-box .overview-box-title span {
  margin-right: 0.5em;
  vertical-align: middle;
}
.overview-box .overview-box-count {
  color: #ffffff;
  font-size: 24px;
  width: 100%;
  display: block;
  padding: 5px 0;
}
.overview-box.overview-box-1 {
  background-color: #007bff;
  border: solid 1px #007bff;
  color: #ffffff;
}
.overview-box.overview-box-2 {
  background-color: #28a745;
  border: solid 1px #28a745;
  color: #ffffff;
}
.overview-box.overview-box-3 {
  background-color: #fd7e14;
  border: solid 1px #fd7e14;
  color: #ffffff;
}
.overview-box.overview-box-4 {
  background-color: #6f42c1;
  border: solid 1px #6f42c1;
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .overview-box {
    margin-bottom: 11px !important;
  }
}
</style>
